import React from 'react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { TextField, Button, MenuItem, Select,  Box, CircularProgress, Typography } from '@mui/material';

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import { AddTrainingCommand, RunnerTraining} from '../../api/models/runner';
import { getDurationFromString } from './runnerUtils';

enum TrainingCategoryOption {
  'RUN' = 'RUN',
  'BIKE' = 'BIKE',
  'SWIM' = 'SWIM'
}

type TrainingFormValues = {
  runDate:Dayjs;
  description:string;
  distance:string;
  duration:string;
  weight:string;
  trainingMethod:TrainingCategoryOption;
};

export interface TrainingFormProps {
    selectedTraining?:RunnerTraining;
    onSubmit: (data:AddTrainingCommand) => void;
}

export function  TrainingForm(props:TrainingFormProps) {

  const { register, handleSubmit, watch, control} = useForm<TrainingFormValues>();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState('');
  const [initialFormData, setInitialFormData] = React.useState<TrainingFormValues>(getFormData(props.selectedTraining));


  function getFormData(selectedTraining?:RunnerTraining): TrainingFormValues {

    const data: TrainingFormValues = {
        runDate: dayjs(),
        description: selectedTraining?.description ? selectedTraining.description : '',
        distance: '',
        duration: '',
        weight: '',
        trainingMethod: TrainingCategoryOption['RUN']
    };
    return data;
  }

  const onSubmit: SubmitHandler<TrainingFormValues> = data => {
    setLoading(true);
    setError('');

    try{
        console.log(data);

        const trngData: AddTrainingCommand = {
          runDate:data.runDate.toDate(),
          description:data.description,
          distance:parseInt(data.distance),
          duration:data.duration ? getDurationFromString(data.duration) : undefined,
          weight:data.weight ? parseInt(data.weight) : undefined,
          trainingMethod:data.trainingMethod

        };

        props.onSubmit(trngData);

    }
    catch(error: any){
      setError(error.message);
    }
    finally{
      setLoading(false);
  }
}

  return (
      <Box  m={2} p={2} border={1} borderColor="grey.500" borderRadius={4}>
      <form onSubmit={handleSubmit(onSubmit)}>
      
      <br/>

<Controller
    name="description"
    control={control}
    defaultValue={initialFormData.description}
    rules={{ required: 'Description is required' }}
    render={({ field, fieldState: { error } }) => (
      <TextField 
        {...field} 
        label="Description" 
        error={!!error} 
        helperText={error ? error.message : null}
        size="small"
        style={{ width: 200 }}
      />
    )}
  />

  <br/><br/>


  <LocalizationProvider dateAdapter={AdapterDayjs}>
  <Controller
    name="runDate"
    control={control}
    rules={{ required: {value:true, message:'Run Date is required'} }}
    defaultValue={initialFormData.runDate}
    render={({ field, fieldState: { error } }) => (
      <DatePicker  
      label="Date"
      value={field.value}
      inputRef={field.ref}
      onChange={(date) => {
        field.onChange(date);
      }}
      />
    )}
  />
  </LocalizationProvider>

  <br/><br/>
  <Controller
    name="duration"
    control={control}
    defaultValue={initialFormData.duration}
    render={({ field, fieldState: { error } }) => (
      <TextField 
        {...field} 
        label="duration" 
        error={!!error} 
        helperText={error ? error.message : null}
        size="small"
        style={{ width: 200 }}
      />
    )}
  />

  <br/><br/>

  <Controller
          name="distance"
          control={control}
          defaultValue={initialFormData.distance}
          rules={{
            pattern: {
              value: /^[0-9]*\.?[0-9]+$/,
              message: 'Only numbers are allowed'
            }
          }}
          render={({ field, fieldState: { error } }) => (
            <TextField 
              {...field} 
              label="distance" 
              error={!!error} 
              helperText={error ? error.message : null}
              size="small"
              style={{ width: 200 }}
            />
          )}
        />

  <br/><br/>

  <Controller
          name="weight"
          control={control}
          defaultValue={initialFormData.weight}
          rules={{
            pattern: {
              value: /^[0-9]*$/,
              message: 'Only numbers are allowed'
            }
          }}
          render={({ field, fieldState: { error } }) => (
            <TextField 
              {...field} 
              label="weight" 
              error={!!error} 
              helperText={error ? error.message : null}
              size="small"
              style={{ width: 200 }}
            />
          )}
        />

  <br/><br/>

  <Controller
      name="trainingMethod"
      control={control}
      defaultValue={initialFormData.trainingMethod}
      render={({ field }) => (
          <Select {...field} size="small" style={{ width: 200 }}>
           {Object.keys(TrainingCategoryOption).map((key) => (
          <MenuItem key={key} value={TrainingCategoryOption[key as keyof typeof TrainingCategoryOption]}>
            {TrainingCategoryOption[key as keyof typeof TrainingCategoryOption]}
          </MenuItem>
        ))}
          </Select>
      )}
      />

  <br/><br/>

  {!loading && 
    <Box display="flex" justifyContent="space-between">
    <Button type="submit" variant="contained" color="primary" style={{ marginRight: '10px' }}>
      Submit
    </Button>
    <Button type="reset" variant="contained" color="primary">
      Clear
    </Button>
  </Box>
     }
  {loading && <CircularProgress />}
  {error && <Typography color="error">{error}</Typography>}


      </form>
    </Box>
  );
};