import React from 'react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { TextField, Button, MenuItem, Select,  Box, CircularProgress, Typography } from '@mui/material';

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import { RaceCommand, RaceInfo } from '../../api/models/race';


enum RaceCategoryOption {
  '3K' = '3K',
  '5K' = '5K',
  '8K' = '8K',
  '10K' = '10K',
  '2mi' = '2 mi',
  'HalfMarathon' = 'Half Marathon',
  'Marathon' = 'Marathon',
  'Adventure' = 'Adventure',
  'Triathlon' = 'Triathlon'
}

type RaceFormValues = {
  raceDate: Dayjs;
  raceName: string;
  shortRaceName: string;
  location: string;
  addressLine1: string;
  city: string;
  state: string;
  zipCode: string;
  logoLink: string;
  webLink: string;
  raceCategoryOption: RaceCategoryOption;
};

export interface RaceFormProps {
    selectedRace?:RaceInfo;
    onSubmit: (data:RaceCommand) => void;
}

export function  RaceForm(props:RaceFormProps) {
  const { register, handleSubmit, watch, control} = useForm<RaceFormValues>();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState('');
  const [initialFormData, setInitialFormData] = React.useState<RaceFormValues>(getFormData(props.selectedRace));

  const watchLogoLink = watch('logoLink');


  function getFormData(selectedRace?:RaceInfo): RaceFormValues {

    const data:RaceFormValues = {
        raceDate: dayjs(),
        raceName: selectedRace?.raceName ? selectedRace.raceName : '',
        shortRaceName: '',
        location: '',
        addressLine1: '',
        city: '',
        state: '',
        zipCode: '',
        logoLink: '',
        webLink: '',
        raceCategoryOption: RaceCategoryOption['5K']
    };
    return data;
  }

  const onSubmit: SubmitHandler<RaceFormValues> = data => {
    setLoading(true);
    setError('');

    try{
        console.log(data);

        const raceInfo:RaceCommand = {
          raceCategoryName:data.raceCategoryOption,
          raceDate:data.raceDate.toDate(),
          raceName:data.raceName,
          shortRaceName:data.shortRaceName,
          location:data.location,
          addressLine1:data.addressLine1,
          city:data.city,
          state:data.state,
          zipCode:data.zipCode,
          logoLink:data.logoLink,
          webLink:data.webLink,
        }

        props.onSubmit(raceInfo);
    }
    catch(error: any){
      setError(error.message);
    }
    finally{
      setLoading(false);
  }
}

  return (
   
<Box  m={2} p={2}>
    <form onSubmit={handleSubmit(onSubmit)}>
      <br/>

      <Controller
          name="raceName"
          control={control}
          defaultValue={initialFormData.raceName}
          rules={{ required: 'Race name is required' }}
          render={({ field, fieldState: { error } }) => (
            <TextField 
              {...field} 
              label="Race name" 
              error={!!error} 
              helperText={error ? error.message : null}
              size="small"
              style={{ width: 200 }}
            />
          )}
        />

        <br/><br/>

        <Controller
          name="shortRaceName"
          control={control}
          defaultValue={initialFormData.raceName}
          render={({ field, fieldState: { error } }) => (
            <TextField 
              {...field} 
              label="Short Race name" 
              error={!!error} 
              helperText={error ? error.message : null}
              size="small"
              style={{ width: 200 }}
            />
          )}
        />

        <br/><br/>


        <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Controller
          name="raceDate"
          control={control}
          rules={{ required: {value:true, message:'Race date is required'} }}
          defaultValue={initialFormData.raceDate}
          render={({ field, fieldState: { error } }) => (
            <DatePicker  
            label="Date"
            value={field.value}
            inputRef={field.ref}
            onChange={(date) => {
              field.onChange(date);
            }}
            />
          )}
        />
        </LocalizationProvider>

        <br/><br/>

        <Controller
          name="location"
          control={control}
          defaultValue={initialFormData.raceName}
          render={({ field, fieldState: { error } }) => (
            <TextField 
              {...field} 
              label="location" 
              error={!!error} 
              helperText={error ? error.message : null}
              size="small"
              style={{ width: 200 }}
            />
          )}
        />

        <br/><br/>

        <Controller
          name="addressLine1"
          control={control}
          defaultValue={initialFormData.raceName}
          render={({ field, fieldState: { error } }) => (
            <TextField 
              {...field} 
              label="address line 1" 
              error={!!error} 
              helperText={error ? error.message : null}
              size="small"
              style={{ width: 200 }}
            />
          )}
        />

        <br/><br/>

        <Controller
          name="city"
          control={control}
          defaultValue={initialFormData.raceName}
          render={({ field, fieldState: { error } }) => (
            <TextField 
              {...field} 
              label="city" 
              error={!!error} 
              helperText={error ? error.message : null}
              size="small"
              style={{ width: 200 }}
            />
          )}
        />

        <br/><br/>

        <Controller
          name="state"
          control={control}
          defaultValue={initialFormData.raceName}
          render={({ field, fieldState: { error } }) => (
            <TextField 
              {...field} 
              label="state" 
              error={!!error} 
              helperText={error ? error.message : null}
              size="small"
              style={{ width: 200 }}
            />
          )}
        />

        <br/><br/>

        <Controller
          name="zipCode"
          control={control}
          defaultValue={initialFormData.raceName}
          render={({ field, fieldState: { error } }) => (
            <TextField 
              {...field} 
              label="zipCode" 
              error={!!error} 
              helperText={error ? error.message : null}
              size="small"
              style={{ width: 200 }}
            />
          )}
        />

        <br/><br/>

        <Controller
          name="logoLink"
          control={control}
          defaultValue={initialFormData.raceName}
          render={({ field, fieldState: { error } }) => (
            <TextField 
              {...field} 
              label="logo link" 
              error={!!error} 
              helperText={error ? error.message : null}
              size="small"
              style={{ width: 200 }}
            />
          )}
        />

        <br/><br/>

        <Controller
          name="webLink"
          control={control}
          defaultValue={initialFormData.raceName}
          render={({ field, fieldState: { error } }) => (
            <TextField 
              {...field} 
              label="web link" 
              error={!!error} 
              helperText={error ? error.message : null}
              size="small"
              style={{ width: 200 }}
            />
          )}
        />

        <br/><br/>


        <Controller
            name="raceCategoryOption"
            control={control}
            defaultValue={initialFormData.raceCategoryOption}
            render={({ field }) => (
                <Select {...field} size="small" style={{ width: 200 }}>
                 {Object.keys(RaceCategoryOption).map((key) => (
                <MenuItem key={key} value={RaceCategoryOption[key as keyof typeof RaceCategoryOption]}>
                  {RaceCategoryOption[key as keyof typeof RaceCategoryOption]}
                </MenuItem>
              ))}
                </Select>
            )}
            />

        <br/><br/>

        {!loading && 
          <Box>
           <Button type="submit" variant="contained" color="primary">Submit</Button>
           <Button type="reset" variant="contained" color="primary">Clear</Button>
           </Box>
           }
        {loading && <CircularProgress />}
        {error && <Typography color="error">{error}</Typography>}

    </form>
    </Box>
  );


};