import React from 'react';
import { useEffect, useState } from "react";
import { Box,CircularProgress, Tab, Tabs } from '@mui/material';
import { ErrorHeader } from "../../shared/errorHeader";

import { useParams } from "react-router-dom";
import { addRunnerRaceApi, addTrainingApi, getRacesApi, getRunnerApi} from "../../api/runnerApi";

import { RunnerContext } from "../../context/runnerContext";

import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { formatHeight } from "../../utils/dataFormatters";
import { AddRunnerRaceCommand, AddTrainingCommand, RunnerInfo} from "../../api/models/runner";
import { useApi } from "../../hooks/useApi";

import { RunnerRaceTab } from './runnerRaceTab';
import { RunnerTrainingTab } from './runnerTrainingTab';
import { TabPanel } from '../../shared/tabPanel';
import { RaceInfo } from '../../api/models/race';

dayjs.extend(duration);

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export function RunnerPage() {
    const [runner, setRunner] = useState<RunnerInfo | undefined>(undefined);
    const [races, setRaces] = useState<RaceInfo[] | undefined>(undefined);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [tabValue, setTabValue] = React.useState(0);

    const handleChangeTabs = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    const addTrainingRequest = useApi(addTrainingApi);
    const addRunnerRaceRequest = useApi(addRunnerRaceApi);
    const getRacesRequest = useApi(getRacesApi);
    
    const params = useParams();

    const birthDate = dayjs(runner?.birthDate);
    const isValidBirthDate = birthDate.isValid();
    let age = 'NA';
    if(isValidBirthDate){
        age = dayjs().diff(birthDate, 'year').toString();
    }


    useEffect(() => {
        initPage();
        getRacesRequest.request();
    }, []);

    useEffect(() => {
        
        if (getRacesRequest.data) {
            const response: RaceInfo[] = getRacesRequest.data.races;
            setRaces(response);
        }
    }, [getRacesRequest.data]);

    const initPage = async () => {
        try {

            setIsLoading(true);
            setErrorMessage('');

            if (!params.runnerGuid) {
                throw Error('Runner GUID is missing');
            }

            await refreshMember(params.runnerGuid);
            setIsLoading(false);
        } 
        catch (error: any) {
            if (error.response?.data) {
                setErrorMessage(error.response.data.message);
            }
            else {
                setErrorMessage(error.message);
            }
            setIsLoading(false);
        }
    }

    async function refreshMember(runnerGuid: string) {
        const response = await getRunnerApi(runnerGuid);
        setRunner(response);
    }

    async function addTraining(data: AddTrainingCommand):Promise<void> {
        try {
            alert('Adding training');
            setErrorMessage('');
            await addTrainingRequest.request(params.runnerGuid!,data);
            await refreshMember(params.runnerGuid!);
        } 
        catch (error: any) {
            if (error.response?.data) {
                setErrorMessage(error.response.data.message);
            }
            else {
                setErrorMessage(error.message);
            }
        }

    }

    async function addRunnerRace(data: AddRunnerRaceCommand):Promise<void> {
        try {
            alert('Adding RunnerRace');
            setErrorMessage('');
            await addRunnerRaceRequest.request(params.runnerGuid!,data);
            await refreshMember(params.runnerGuid!);
        } 
        catch (error: any) {
            if (error.response?.data) {
                setErrorMessage(error.response.data.message);
            }
            else {
                setErrorMessage(error.message);
            }
        }
    }

    const mainContent = () => {

        return (
            
                <Box>
                    <br/>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img src={runner?.orgSeason?.logoLink} height={50} alt='profile' />
                        {runner?.orgSeason && <h2 style={{ margin: '0 10px' }}>{runner?.orgSeason.name}</h2>}
                    </div>

                    <h1>{runner?.firstName}  {runner?.lastName} </h1>
                    {<img src={runner?.profileLink} height={200} alt='profile' />}
                    

                    <br />
                    
                    <h2>Bio</h2>
                    <table>
      <tbody>
        <tr>
          <td>Height:</td>
          <td><strong>{formatHeight(runner?.height)}</strong></td>
        </tr>
        <tr>
          <td>Weight:</td>
          <td><strong>{runner?.weight ? runner.weight : 'NA'}</strong></td>
        </tr>
        <tr>
          <td>Birth Date:</td>
          <td><strong>{isValidBirthDate ? birthDate.format('MM/DD/YY') : 'NA'}</strong></td>
        </tr>
        <tr>
          <td>Age:</td>
          <td><strong>{age}</strong></td>
        </tr>
      </tbody>
    </table>

    <h2>Personal Records</h2>

    <table>
        <thead><tr><td>Category</td><td>#</td><td>PR</td></tr></thead>
      <tbody>
        {runner?.raceSummary && 
            runner?.raceSummary?.map( item => 
            <tr>
                <td>{item.raceCategory}</td>
                <td>{item.numRaces}</td>
                <td>{item.minDuration}</td>
            </tr> )
        }

        </tbody>
        </table>


    <Box>

        <RunnerContext.Provider value={{ runner, races, addTraining, addRunnerRace }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={tabValue} onChange={handleChangeTabs} aria-label="simple tabs example">
                            <Tab label="Races" {...a11yProps(0)} />
                            <Tab label="Training" {...a11yProps(1)} />
                        </Tabs>
                    </Box>
                    <TabPanel value={tabValue} index={0}>
                        {runner?.races && <RunnerRaceTab />}
                    </TabPanel>
                    <TabPanel value={tabValue} index={1}>
                        {runner?.training && <RunnerTrainingTab />}
                    </TabPanel>
                    </RunnerContext.Provider>
                    
        </Box>

                    <br/><br/>
                    {runner?.qrCodeLink && <img src={runner?.qrCodeLink} height={200} alt='profile' />} 
                   

                    <br />

    </Box>
    );
    }

    return (<Box>
        <ErrorHeader errorMessage={errorMessage} />
        {isLoading && <CircularProgress />}
        {runner && mainContent()}
    </Box>
    );

}