import axios from 'axios';
import { AddImageCommand } from './models/common';
import { CreateOrganizationCommand, Organization, OrganizationList } from "./models/organization";

export async function updateOrganizationLogoApi(bearerToken: string, organizationId: string, imageData: AddImageCommand): Promise<void> {
  const baseUrl = process.env.REACT_APP_API_URL;
  const endpoint = `/api/v1/organization/${organizationId}/logo`;
  await axios.put(baseUrl + endpoint, imageData, { timeout: 60000, headers: { 'Authorization': `Bearer ${bearerToken}` } });
}

export async function getOrganizations(bearerToken: string): Promise<Organization[]> {
  const baseUrl = process.env.REACT_APP_API_URL;
  const endpoint = "/api/v1/organization";

  const headers: Record<string, string> = {};

  if (bearerToken) {
    headers['Authorization'] = `Bearer ${bearerToken}`;
  }

  const response = await axios.get(baseUrl + endpoint, { timeout: 60000, headers });
  const organizationList = response.data as OrganizationList;
  return organizationList.organizations;
}

export async function getSingleOrganizationApi(bearerToken: string, orgId: string): Promise<Organization> {
  const baseUrl = process.env.REACT_APP_API_URL;
  const endpoint = `/api/v1/organization/${orgId}`;

  const headers: Record<string, string> = {};

  if (bearerToken) {
    headers['Authorization'] = `Bearer ${bearerToken}`;
  }

  const response = await axios.get(baseUrl + endpoint, { timeout: 60000, headers });
  return response.data as Organization;
}

export async function createOrganizationApi(bearerToken: string, createOrgCommand: CreateOrganizationCommand): Promise<void> {
  console.log(`create Org Command`);
  console.log(createOrgCommand);
  const baseUrl = process.env.REACT_APP_API_URL;
  const endpoint = "/api/v1/organization";
  await axios.post(baseUrl + endpoint, createOrgCommand, { timeout: 60000, headers: { 'Authorization': `Bearer ${bearerToken}` } });
}

export async function deleteOrganizationApi(bearerToken: string, orgId: string): Promise<void> {
  const baseUrl = process.env.REACT_APP_API_URL;
  const endpoint = `/api/v1/organization/${orgId}`;
  await axios.delete(baseUrl + endpoint, { timeout: 60000, headers: { 'Authorization': `Bearer ${bearerToken}` } });
}






