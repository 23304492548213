import axios from 'axios';
import { AddRunnerRaceCommand, AddTrainingCommand, RunnerInfo} from './models/runner';
import { RaceCommand, RaceInfo, RacesResponse } from './models/race';

export async function getRunnerApi(runnerGuid: string): Promise<RunnerInfo> {
    const baseUrl = process.env.REACT_APP_API_URL;
    const endpoint = `/api/v1/runner/${runnerGuid}`;
    const response = await axios.get(baseUrl + endpoint, { timeout: 60000});
    return response.data as RunnerInfo;
  }

  export async function addTrainingApi(bearerToken:string, runnerGuid:string, training:AddTrainingCommand): Promise<void> {
    const baseUrl = process.env.REACT_APP_API_URL;
    const endpoint = `/api/v1/runner/${runnerGuid}/training`;
    await axios.post(baseUrl + endpoint, training, { timeout: 60000, headers: { 'Authorization': `Bearer ${bearerToken}` }});
  }

  export async function addRunnerRaceApi(bearerToken:string, runnerGuid:string, runnerRace:AddRunnerRaceCommand): Promise<void> {
    const baseUrl = process.env.REACT_APP_API_URL;
    const endpoint = `/api/v1/runner/${runnerGuid}/race`;
    await axios.post(baseUrl + endpoint, runnerRace, { timeout: 60000, headers: { 'Authorization': `Bearer ${bearerToken}` }});
  }

  export async function getRacesApi(bearerToken:string): Promise<RacesResponse> {
    const baseUrl = process.env.REACT_APP_API_URL;
    const endpoint = `/api/v1/race`;
    const response = await axios.get(baseUrl + endpoint, { timeout: 60000});
    return response.data as RacesResponse;
  }

  export async function getRaceApi(raceGuid:string): Promise<RaceInfo> {
    const baseUrl = process.env.REACT_APP_API_URL;
    const endpoint = `/api/v1/race/${raceGuid}`;
    const response = await axios.get(baseUrl + endpoint, { timeout: 60000});
    return response.data as RaceInfo;
  }

  export async function addRaceApi(bearerToken:string,race:RaceCommand): Promise<void> {
    const baseUrl = process.env.REACT_APP_API_URL;
    const endpoint = `/api/v1/race`;
    const response = await axios.post(baseUrl + endpoint, race, { timeout: 60000, headers: { 'Authorization': `Bearer ${bearerToken}` }});
  }