import { Box, Button, CircularProgress } from "@mui/material";
import { ErrorHeader } from "../../shared/errorHeader";
import { useEffect, useState } from "react";
import { RaceCommand, RaceInfo } from "../../api/models/race";
import { MiniGrid, RowItem } from "../../shared/miniGrid";
import dayjs from 'dayjs';
import { useApi } from "../../hooks/useApi";
import { addRaceApi, getRacesApi } from "../../api/runnerApi";
import {RaceForm} from "./raceForm";
import { BasicButton } from "../../shared/basicButton";


export function RacePage() {

        const [races, setRaces] = useState<RaceInfo[] | undefined>(undefined);
        const [selectedRace, setSelectedRace] = useState<RaceInfo | undefined>(undefined);

        const [showForm, setShowForm] = useState<boolean>(false);
        const [raceRows, setRaceRows] = useState<RowItem[] | undefined>(undefined);
        const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
        const [isLoading, setIsLoading] = useState<boolean>(false);

            const getRacesRequest = useApi(getRacesApi);
            const addRacesRequest = useApi(addRaceApi);

            useEffect(() => {
                initPage();
            }, []);

            useEffect(() => {
              if (getRacesRequest.data) {
                 const response: RaceInfo[] = getRacesRequest.data.races;
                  setRaces(response);
                  mapRacesToRows(response);
              }

          }, [getRacesRequest.data]);

        const initPage = async () => {
            try {
    
                setIsLoading(true);
                setErrorMessage('');
                await getRacesRequest.request();
            } 
            catch (error: any) {
                if (error.response?.data) {
                    setErrorMessage(error.response.data.message);
                }
                else {
                    setErrorMessage(error.message);
                }
                setIsLoading(false);
            }
        }
    
        function mapRacesToRows(responseRaces?: RaceInfo[]) {
            if (responseRaces && responseRaces.length > 0) {
                const tempRows = responseRaces.map((rowItem) => ({
                    id: rowItem.raceDate.toString(),
                    items: [{ name: 'Date', value:dayjs(rowItem.raceDate).format('MM/DD/YY') }, 
                            { name: 'Name', value: rowItem.raceName},
                            { name: 'Category', value: rowItem.raceCategoryName},
                            { name: 'Location', value: rowItem.location},
                            
                        ]
                }));
    
                setRaceRows(tempRows);
            }
        }

        function handleFormSubmit(data:RaceCommand) {
            addRacesRequest.request(data);
        }

            

        function mainContent() {
            return (
                <Box>
                  <h1>Race Page</h1> 
                  {!showForm && <Box sx={{ margin: "10px" }} >
                  <BasicButton   onClick={() => setShowForm(true)} labelText={"Add Race"}/>

               </Box>}

                   {showForm && <RaceForm selectedRace={selectedRace} onSubmit={handleFormSubmit}/>}

                   
                    {raceRows && <MiniGrid rows={raceRows} width={500} />}
                  
                      
                </Box>);
        }

        return (<Box>
            <ErrorHeader errorMessage={errorMessage} />
            {getRacesRequest.loading && <CircularProgress />}
            {races && mainContent()}
        </Box>
        );

}