import { createContext, useContext } from "react"
import { AddRunnerRaceCommand, AddTrainingCommand, RunnerInfo } from "../api/models/runner"
import { RaceInfo } from "../api/models/race"



export type RunnerContent = {
    runner: RunnerInfo | undefined,
    races:RaceInfo[] | undefined,
    addTraining : (data: AddTrainingCommand) =>Promise<void>,
    addRunnerRace: (data: AddRunnerRaceCommand) => Promise<void>
}

export const RunnerContext = createContext<RunnerContent>({
    runner: undefined,
    races: undefined,
    addTraining: async (data: AddTrainingCommand) => {},
    addRunnerRace: async (data: AddRunnerRaceCommand) => {}
})

export const useRunnerContext = () => useContext(RunnerContext)