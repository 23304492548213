
export function getDurationFromString(durationString: string): number {

    const parts = durationString.split(':').map(Number);

    let hours = 0;
    let minutes = 0;
    let seconds = 0;
  
    if (parts.length === 3) {
      [hours, minutes, seconds] = parts;
    } else if (parts.length === 2) {
      [minutes, seconds] = parts;
    } else {
      throw new Error('Invalid duration format. Expected "HH:MM:SS" or "MM:SS".');
    }
  
    return hours * 3600 + minutes * 60 + seconds;
}