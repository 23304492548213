import React from 'react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { TextField, Button, MenuItem, Select, InputLabel, FormControl, FormHelperText, CircularProgress, Box, Typography } from '@mui/material';
import { RaceInfo } from '../../api/models/race';
import { AddRunnerRaceCommand } from '../../api/models/runner';
import { useRunnerContext } from '../../context/runnerContext';
import dayjs from 'dayjs';

type RunnerRaceFormValues = {
  raceGuid: string;
  duration: string;
};

export interface RaceRunFormProps {
    selectedRace?:RaceInfo;
    onSubmit: (data:AddRunnerRaceCommand) => void;
}

export function  RunnerRaceForm() {
  const { register, handleSubmit, watch, control} = useForm<RunnerRaceFormValues>();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState('');

  const { runner, races, addRunnerRace } = useRunnerContext();

  const sixMonthsAgo = dayjs().subtract(6, 'month');


  let filteredAndSortedRaces:RaceInfo[] | undefined = undefined;

  if(races){
    filteredAndSortedRaces = races
    .filter((race) => dayjs(race.raceDate).isAfter(sixMonthsAgo))
    .sort((a, b) => dayjs(b.raceDate).unix() - dayjs(a.raceDate).unix());
  }


  const onSubmit: SubmitHandler<RunnerRaceFormValues> = data => {
    console.log(data);
  };

  return (
<>
{races && <Box>

    <form onSubmit={handleSubmit(onSubmit)}>
      
<Box>
      <Controller
      name="raceGuid"
      control={control}
      defaultValue={races[0].raceGuid}
      render={({ field }) => (
          <Select {...field} size="small" style={{ width: 400 }}>
           {filteredAndSortedRaces && filteredAndSortedRaces.map((race) => (
            <MenuItem key={race.raceGuid} value={race.raceGuid}>
            {dayjs(race.raceDate).format('MM/DD/YY')} - {race.raceName} ({race.raceCategoryName})
          </MenuItem>
            ))}
          </Select>
      )}
      />

       <br/><br/>
        <Controller
          name="duration"
          control={control}
          defaultValue=""
          render={({ field, fieldState: { error } }) => (
            <TextField 
              {...field} 
              label="Duration" 
              error={!!error} 
              helperText={error ? error.message : null}
              size="small"
              style={{ width: 200 }}
            />
          )}
        />

</Box>
      
      

  <br/><br/>

  {!loading && 
    <Box>
     <Button type="submit" variant="contained" color="primary">Submit</Button>
     <Button type="reset" variant="contained" color="primary">Clear</Button>
     </Box>
     }
  {loading && <CircularProgress />}
  {error && <Typography color="error">{error}</Typography>}

    </form>

    </Box>}
    </>);
};