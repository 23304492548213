import { Box } from "@mui/material";
import { BasicButton } from "../../shared/basicButton";
import { useEffect, useState } from "react";
import { RunnerTraining } from "../../api/models/runner";
import { TrainingForm } from "./trainingForm";
import { MiniGrid, RowItem } from "../../shared/miniGrid";
import dayjs from 'dayjs';
import { useRunnerContext } from "../../context/runnerContext";


export function RunnerTrainingTab() {
    const [trainingRows, setTrainingRows] = useState<RowItem[] | undefined>(undefined);
    const [showAddTraining, setShowAddTraining] = useState<boolean>(false); 
    const [selectedTraining, setSelectedTraining] = useState<RunnerTraining | undefined>(undefined);

    const { runner,addTraining } = useRunnerContext();

    useEffect(() => {
        if (runner && runner.training) {
            mapTrainingToRows(runner.training);
        }
    }, [runner?.training]);



    function mapTrainingToRows(responseTraining?: RunnerTraining[]) {

        const threeMonthsAgo = dayjs().subtract(3, 'month');

        const trainingDesc = responseTraining
          ?.filter((training) => dayjs(training.runDate).isAfter(threeMonthsAgo))
          .sort((a, b) => dayjs(b.runDate).unix() - dayjs(a.runDate).unix());

          
        if (trainingDesc && trainingDesc.length > 0) {
            const tempRows = trainingDesc.map((rowItem) => ({
                id: rowItem.runDate.toString(),
                items: [{ name: 'Date', value:dayjs(rowItem.runDate).format('MM/DD/YY') }, 
                        { name: 'Description', value: rowItem.description},
                        { name: 'Miles', value: rowItem.distance.toString()},
                        { name: 'Time', value: rowItem.formattedDuration ?? ""},
                        { name: 'Pace', value: rowItem.pace?.toString() ?? ""},
                        
                    ]
            }));

            setTrainingRows(tempRows);
        }
    }

    function handleTrainingFormSubmit(data: RunnerTraining) {
        addTraining(data);
    }

    return(<Box>
                    <h2>Training</h2>

                    <Box sx={{ margin: "10px" }} >
                    <BasicButton   onClick={() => setShowAddTraining(!showAddTraining)} labelText={"Add Training"}/>
                    </Box>

                    {showAddTraining && <TrainingForm selectedTraining={selectedTraining} onSubmit={handleTrainingFormSubmit} />}
                    {trainingRows && <MiniGrid rows={trainingRows} width={800} />}
    </Box>);

}