import { Box } from "@mui/material";
import { BasicButton } from "../../shared/basicButton";
import { useEffect, useState } from "react";
import { RunnerRaceForm } from "./runnerRaceForm";
import dayjs from 'dayjs';
import { useRunnerContext } from "../../context/runnerContext";
import { RunnerRaces } from "../../api/models/runner";
import { DataGrid, GridColDef } from "@mui/x-data-grid";

interface RowItem {
    raceGuid: string;
    date: string;
    name: string;
    category: string;
    location: string;
    time: string;
    pace: string;
  }
  

export function RunnerRaceTab() {
    const [showAddRace, setShowAddRace] = useState<boolean>(false); 
    const [raceRows, setRaceRows] = useState<RowItem[] | undefined>(undefined);

    const { runner, races, addRunnerRace } = useRunnerContext();

    useEffect(() => {
        if (runner && runner.races) {
            mapRacesToRows(runner.races);
        }
    }, [runner?.races]);



    const columns: GridColDef[] = [
        { field: 'date', headerName: 'Date', width: 100 },
        { field: 'name', headerName: 'Name', width: 200 },
        { field: 'category', headerName: 'Category', width: 100 },
        { field: 'location', headerName: 'Location', width: 200 },
        { field: 'time', headerName: 'Time', width: 100 },
        { field: 'pace', headerName: 'Pace', width: 100 },
       
      ];


    function mapRacesToRows(responseRaces?: RunnerRaces[]) {

        const racesDesc = responseRaces?.sort((a, b) => {
            return dayjs(b.raceDate).unix() - dayjs(a.raceDate).unix();
        });

        if (racesDesc && racesDesc.length > 0) {
            const tempRows = racesDesc.map((rowItem) => ({
                raceGuid: rowItem.raceGuid,
                date: dayjs(rowItem.raceDate).format('MM/DD/YYYY'),
                name: rowItem.raceName,
                category: rowItem.raceCategory,
                location: rowItem.location,
                time: rowItem.formattedDuration ?? '',
                pace: rowItem.pace?.toString() ?? ''
            }));

            setRaceRows(tempRows);
        }
    }


    return(<Box>
        <h2>Races</h2>
                    {!showAddRace && <Box sx={{ margin: "10px" }} >
                    <BasicButton   onClick={() => setShowAddRace(true)} labelText={"Add Race"}/>
                    </Box>}

                    {showAddRace && <RunnerRaceForm/>}

    <div style={{ width: 800 }}>        
      <DataGrid
        autoHeight
        rows={raceRows ?? []}
        density='compact'
        disableSelectionOnClick
        
        pageSize={25}
        columns={columns}
        style={{backgroundColor:'#FFFFFF'}}
        getRowId={(row) => row.raceGuid}
      />
      </div>

    </Box>);

}

