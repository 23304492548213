

export function convertStringToDuration(durationString:string):number{
    
    let seconds:number = 0;

    const arrTime = durationString.split(':');
    if(arrTime.length === 3){
        seconds = (Number(arrTime[0]) * 3600) + (Number(arrTime[1]) * 60) + Number(arrTime[2]);
    }
    else if(arrTime.length === 2){
        seconds = Number(arrTime[0]) * 60 + Number(arrTime[1]);
    }
    else if(arrTime.length === 1){
        seconds = Number(arrTime[0])
    }

    return seconds;
}

export function convertDurationToString(seconds:number):string{
    let durationString = '';
    const minute = 60;
    const hour = 3600;

    if(seconds === 0){
        durationString = 'N/A';
    }
    else{
       if(seconds >= hour){
        durationString = `${seconds}`;
       }
       else if (seconds >= minute){
        durationString = `${seconds}`;
       }
       else{
        durationString = `${seconds}`;
       }

    }
    return durationString;
}

export function formatHeight(height?:number):string{
    if(!height){
        return 'NA';
    }

    const feet = Math.floor(height / 12);
    const inches = height % 12;
    const formattedHeight = `${feet}' - ${inches}"`;
    return formattedHeight;
}